.loaderForData-global {
  position: absolute;
  top: calc(50% - 40px);
  left: calc(50% - 40px);
}

.loaderForData-opacity {
  position: relative;
  height: 100%;
  width: 100%;
  flex: 1 1 auto;
  .loaderForData {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: calc(50% - 40px);
    left: calc(50% - 40px);
    transition: visibility 0s linear 1.4s, opacity 1.3s;
  }
  .opacity {
    opacity: 1;
    transition: opacity .6s;
  }
  &.on {
    pointer-events: none;
    .loaderForData {
      opacity: 1;
      visibility: visible;
      transition: visibility 0s linear 0s, opacity .3s;
    }
    .opacity {
      opacity: 0.6;
    }
  }
}
