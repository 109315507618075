@import "assets/styles/mixins";
@import "./styles";

:root {
  --TT: "TT Norms Pro", sans-serif;
  --Inter: "Inter", sans-serif;
  --Noto: 'Noto Sans', sans-serif;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100%;
  }
}
.appear {
  animation: appear 0.5s;
}
#scrollableTargetId:hover::-webkit-scrollbar-thumb {
  background-color: var(--primaryLight);
}
::-webkit-scrollbar {
  background-color: transparent;
  width: 2px;
  height: 2px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: var(--gray600);
  border-radius: 8px;
}
::-webkit-scrollbar-button {
  display: none;
}
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  min-height: 100vh;
  min-height: 100dvh;
  font-family: var(--Inter);
  line-height: 1.3;
  color: var(--black);
  background-color: var(--white);
  overflow-x: hidden;
}
h2 {
  font-size: 32px;
  @include screen_sm_max {
    font-size: 26px;
  }
}
h4 {
  font-size: 24px;
  @include screen_sm_max {
    font-size: 20px;
  }
}
img {
  max-width: 100%;
  height: auto;
  display: block;
}
a {
  outline: none;
  &:active {
    color: var(--primaryDarken);
  }
}
svg {
  display: block;
}

.ellipsis {
  @include ellipsis;
  background-color: inherit;
  &:hover {
    overflow: visible;
    position: relative;
    .expand {
      user-select: none;
      display: inline-block;
      vertical-align: top;
      background-color: inherit;
      padding-right: 8px;
    }
  }
}
.ellipsisWithoutHover {
  @include ellipsis;
}
.ellipsis-multi {
  @include ellipsis-multi;
}

.w100 {
  width: 100%;
}
.fixMuiButtonFontSize {
  &.MuiIconButton-root {
    font-size: 1rem;
  }
}
.MuiSnackbarContent-root {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1) !important;
  border-radius: 10px !important;
  padding: 15px 25px !important;
  font-size: 15px !important;
  svg {
    color: var(--white);
  }
}

.MuiSelect-selectMenu {
  white-space: normal !important;
  height: 48px;
  // height: auto !important;
  // padding: 14.5px 28px 14.5px 14px !important;
}

//forms
.MuiFormHelperText-root {
  white-space: nowrap;
  position: absolute;
  bottom: -20px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}
.authForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: 40px auto 60px;
  flex-grow: 1;
  @include screen_sm {
    max-width: 320px;
  }
  @include screen_lg {
    max-width: 260px;
    margin-top: 0;
    margin-bottom: 0;
  }
  .MuiButtonBase-root {
    margin-left: 0;
    width: 100%;
  }
  .MuiAutocomplete-endAdornment .MuiButtonBase-root {
    width: auto;
  }
  .authForm__error {
    text-align: center;
    color: var(--error);
    margin-top: 15px;
  }
  .authForm__credits {
    text-align: center;
    padding: 22px 15px 0 15px;
    font-size: 13px;
    font-weight: 500;
    display: inline;
    color: var(--gray800);
    a {
      color: var(--success);
    }
  }
  .authForm__inputBox {
    position: relative;
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
      padding: 0 !important;
      input {
        padding-left: 14px;
        height: 37px;
      }
    }
    .MuiFormLabel-root.Mui-error {
      color: var(--gray800);
    }
    .MuiFormLabel-root.Mui-focused {
      display: none;
    }
    .authForm__adding {
      display: flex;

      button {
        margin-left: 10px;
        min-width: 102px;
      }
    }
    .authForm__input {
      @include input-hover;
      border-radius: 10px;
      font-size: 20px;
    }
    .authForm__icon {
      position: absolute;
      top: 18px;
      right: 16px;
      &_show {
        cursor: pointer;
      }
    }
  }
  .authForm__label {
    display: block;
    color: var(--gray800);
    margin-bottom: 3px;
    @include text-body-s;
  }
  .authForm__link {
    color: var(--gray800);
    font-size: 16px;
    text-decoration: underline;
    margin-bottom: 20px;
    &_center {
      text-align: center;
      margin-top: 30px;
    }
  }
}
.authForm__input {
  font-size: 20px;
  .MuiSelect-selectMenu {
    padding: 18.5px 14px !important;
  }
}
.generalForm {
  .generalForm__inputBox {
    label.Mui-focused {
      display: none;
    }
    .generalForm__label {
      color: var(--gray800);
      margin-bottom: 7px;
    }
    &_date {
      .MuiBox-root {
        .MuiFormControl-root {
          width: 100%;
          .MuiInputBase-root {
            min-height: 56px;
            background: var(--gray400);
            border-radius: 12px;
            padding-left: 16px;
            cursor: pointer;
            &::before,
            &::after {
              display: none;
            }
          }
        }
        .date-picker__icon {
          position: absolute;
          top: 10px;
          right: 11px;
        }
      }
    }
  }
}
.resetPass {
  .MuiButton-root {
    margin-top: 8px;
  }
}
.newPass {
  .authForm {
    margin-top: 65px;
    margin-bottom: 46px;
  }
  .MuiButton-root {
    margin-top: 8px;
  }
}
.table {
  &__container {
    width: 100%;
    .table {
      border-collapse: separate;
      border-spacing: 0 8px;
    }
    &.MuiPaper-root {
      box-shadow: none;
    }
    .table__head {
      padding: 0px 10px;
      @include screen_lg {
        padding: 0px 5px;
      } 
    }
    .table__th {
      padding: 0 8px;
      border-bottom: none;
      font-family: var(--Inter);
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 22px;
      color: var(--gray800);
      &:first-child {
        padding-left: 8px;
      }
    }
    .table__td {
      border-bottom: none;
      border-spacing: 4px;
      padding-top: 0;
      padding-bottom: 0;
      font-family: var(--TT);
      font-style: normal;
      font-weight: bold;
      font-size: 19px;
      line-height: 24px;
      color: var(--black);
      &:hover {
        overflow: visible;
        .td-ellipsis {
          z-index: 2222;
          position: relative;
          background-color: var(--gray300);
          width: fit-content;
          max-width: 666px;
          text-shadow: 0px 0px 3px var(--gray400);
          padding-right: 8px;
          border-radius: 4px;
        }
      }
    }
    .table__row-bg {
      margin-bottom: 10px;
      padding: 20px 10px;
      transition: box-shadow 0.1s;
      &:hover {
        box-shadow: inset 0px 0px 1px 3px var(--grayC5);

        .table__date-cell {
          color: var(--black111);
        }
      }
      @include screen_lg {
        padding: 10px 5px;
      }
      @include screen_md_max {
        padding-bottom: 10px;
      }
      .table__td {
        background: var(--gray300);
        &:first-child {
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
          padding-left: 0;
        }
        &:last-child {
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          padding-right: 0;
          @include screen_md {
            padding-right: 30px;
            justify-content: flex-end;
          }
        }
      }
    }
  }
  &__td {
    white-space: nowrap;
    .td-ellipsis {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &__date-cell {
    font-family: var(--Inter);
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: var(--gray800);
  }
  &__product-cell {
    font-family: var(--TT);
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    line-height: 24px;
    color: var(--black);
    word-break: break-all;
    padding-top: 2px;
  }
  &__info-cell {
    flex-direction: column;
    align-items: flex-start;
    padding: 0 16px;
    // margin-top: 8px;
    @include screen_lg_max {
      width: 100%;
    }
    @include screen_md {
      padding: 0;
    }
    @include screen_lg {
      margin-top: 0;
    }
    &__title {
      width: 100%;
      font-family: var(--Inter);
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 22px;
      color: var(--black);
    }
    &__desc {
      font-family: var(--Inter);
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 18px;
      color: var(--gray800);
    }
  }
  // .table__company-cell
  &__company-cell {
    font-family: var(--Inter);
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    color: var(--black);
    margin-top: 16px;
    padding: 0 16px;
    &.owner {
      color: var(--gray800);
      font-size: 13px;
      line-height: 18px;
    }
    @include screen_lg_max {
      max-width: unset;
    }
    @include screen_md {
      margin-top: 0;
      padding: 0;
    }
    @include screen_md_max {
      margin-top: 4px;
    }
  }
  // &__status-cell {
  // }
  &__bidding-cell {
    margin-top: 16px;
    padding: 0 16px;
    justify-content: space-between;
    @include screen_md {
      padding: 0;
      margin-top: 0;
      width: 100%;
    }
    @include screen_md_max {
      margin-top: 0px;
    }
    &__label {
      flex-grow: 1;
      margin-left: 8px;
      font-family: var(--Inter);
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 18px;
      color: var(--gray800);
      white-space: nowrap;
    }
    // .table__bidding-cell__icon-favorite
    &__icon-favorite {
      svg {
        width: 18px;
        height: 18px;
      }
      path {
        stroke: var(--gray800);
      }
      &.isFavorite {
        path {
          fill: var(--gray800);
          stroke: var(--gray800);
        }
      }
      &.disabledStop {
        cursor: not-allowed;
        opacity: .3;
      }
    }
  }
}
.table__row-bg {
  background: var(--gray300);
  border-radius: 10px;
  // overflow: hidden;
  margin-bottom: 10px;
  .font-400 {
    font-weight: 400;
  }
  .date-label__cell {
    padding-left: 32px;
    font-size: 12px;
    font-family: monospace;
    color: var(--black555);
  }
  &:last-child {
    margin-bottom: 0;
  }
}
.fixed-button-container {
  mask-image: linear-gradient(0deg, black 80%, transparent);
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px 24px 25px;
  background: var(--white);
  z-index: 10;
  @include screen_sm_max {
    button {
      width: 100%;
    }
  }
}
.section-content {
  display: flex;
  position: relative;
  padding-bottom: 111px !important;
  @include screen_lg {
    margin-bottom: 0;
  }
  @include screen_lg_max {
    padding-bottom: 40px;
  }
}
.block-middle-width {
  width: 100%;
  @include screen_lg {
    max-width: 448px;
  }
}
.block-small-width {
  width: 100%;
  @include screen_lg {
    max-width: 352px;
  }
}
.text-heading-h4 {
  @include text-heading-h4;
}
.text-heading-h5 {
  @include text-heading-h5;
}
.text-heading-h6 {
  @include text-heading-h6;
}
.text-body-m {
  @include text-body-m;
}
.text-body-bold-m {
  @include text-body-bold-m;
}
.text-body-bold-s {
  @include text-body-bold-s;
}
.text-body-s {
  @include text-body-s;
}
.text-body-xs {
  @include text-body-xs;
}
.resetPasswordDialog__btn {
  max-width: 700px !important;
}
.link {
  @include link;
}
.link-bold {
  @include link-bold;
}
.link-underline {
  @include link;
  text-decoration: underline;
}
.navigate-block {
  &__aside {
    position: relative;
    z-index: 10;
    width: 100%;
    display: inline-block;
    @include screen_md {
      position: fixed;
      width: auto;
      padding-right: 48px;
      margin-right: 16px;
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      min-height: 360px;
      width: 1px;
      background: var(--gray500);
      @include screen_md_max {
        display: none;
      }
    }
    @include screen_md_max {
      display: none;
      &.active {
        display: block;
      }
    }
  }
  &__link {
    padding: 10px 0;
    position: relative;
    display: flex;
    align-items: center;
    transition: 0.3s;
    width: 100%;
    cursor: pointer;
    @include screen_lg {
      width: 230px;
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      background: rgba(0, 0, 0, 0.04);
      border-radius: 10px;
      opacity: 0;
      transition: 0.3s;
    }
    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 0;
      background: var(--gray500);
      width: 100%;
      height: 1px;
      @include screen_md_max {
        width: 100%;
      }
    }
    &:hover {
      &::before {
        opacity: 1;
      }
    }
    span {
      padding: 0 10px;
      color: var(--gray800);
    }
    .svg-icon-container {
      display: none;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -61px;
    }
    @include screen_md {
      &.active {
        span {
          color: var(--black);
        }
        .svg-icon-container {
          background: var(--white);
          display: inline-flex;
          @include screen_md_max {
            display: none;
          }
        }
      }
    }
  }
}
.grey-button.MuiButton-root {
  @include text-button-m;
  padding: 11px 16px;
  border-radius: 10px;
  background: var(--gray400);
  color: var(--primaryDarken);
  text-transform: inherit;
  width: 100%;
  @include screen_lg {
    width: auto;
  }
  &:hover {
    background: var(--primary);
    color: var(--white);
  }
}
.green-button.MuiButton-root {
  @include text-button-m;
  padding: 11px 16px;
  border-radius: 10px;
  background: var(--primaryLight);
  color: var(--white);
  text-transform: inherit;
  width: 100%;
  @include screen_lg {
    width: auto;
  }
  &:hover {
    background: var(--primaryDarken);
    color: var(--white);
  }
}
.block-container {
  // .block-container__item
  &__item {
    display: flex;
    align-items: center;
    margin: 8px 0;
    &.with-bg {
      padding: 12px 16px;
      border-radius: 10px;
      &:nth-of-type(2n) {
        background: var(--gray300);
      }
    }
  }
  // .block-container__column
  &__column {
    display: flex;
    flex-direction: column;
  }
  // .block-container__part
  &__part {
    width: 50%;
    margin-left: -8px;
    padding-left: 8px;
    &:first-of-type {
      padding-right: 40px;
    }
  }
  // .block-container__label
  &__label {
    @include text-body-s;
    color: var(--gray800);
    @include screen_lg {
      @include text-body-m;
    }
  }
  // .block-container__value
  &__value {
    @include text-body-s;
    color: var(--black);
    @include screen_lg {
      @include text-body-m;
    }
  }
}
.cursor-pointer {
  cursor: pointer;
}
.switchBox {
  background: var(--gray400);
  overflow: hidden;
  min-height: 40px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    border-radius: 12px;
    z-index: 1;
    top: 0;
    left: 0;
    transform: translateX(50%);
    height: 100%;
    background: var(--primary);
    width: 50%;
    opacity: 0;
    transition: 0.5s cubic-bezier(0.19, 0.94, 1, 1);
  }
  &.active {
    &::before {
      transform: translateX(0);
      opacity: 1;
    }
  }
  &.right {
    &::before {
      transform: translateX(100%);
    }
  }
  .switchBox__item {
    width: 100%;
    cursor: pointer;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
    position: relative;
    z-index: 10;
    &.active {
      color: var(--white);
    }
  }
  .switchBox__item-filter {
    position: relative;
    width: 100%;
    cursor: pointer;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
    font-size: 15px;
    line-height: 22px;
    z-index: 10;
    background-color: var(--grayFA);
    &:before {
      position: absolute;
      content: "";
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      border-radius: 10px;
      background-color: var(--gray400);
    }
    &.active {
      color: var(--white);
      &:before {
        background: var(--primary);
      }
      &:hover {
        &:before {
          background-color: var(--CTA);
        }
      }
    }
    &:hover {
      &:before {
        background-color: var(--gray500);
      }
    }
  }
}
.badge {
  @include text-body-xxs;
  position: absolute;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  right: -5px;
  top: -7px;
  width: 20px;
  height: 20px;
  background: var(--error);
  border-radius: 50%;
  color: var(--white);
}
.badge-for-list {
  @include text-body-xxs;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 24px;
  height: 24px;
  background: var(--error);
  border-radius: 50%;
  color: var(--white);
}
.form-loader.loaderForData {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  .loaderForData {
    position: relative;
    left: 50%;
    top: 50%;
    margin-left: -40px;
  }
}
button.button-link {
  @include text-body-s;
  text-decoration: underline;
  text-transform: inherit;
  color: var(--primaryDarken);
}
.hidden {
  display: none !important;
}
.vhidden {
  visibility: hidden;
}

.hidden-mobile {
  @include screen_lg_max {
    display: none !important;
  }
}

.opacity_0 {
  opacity: 0;
}
div.custom-select {
  border-radius: 12px;
  background: var(--gray400);
  border: none;
  &.select-multiple {
    .MuiSelect-root {
      padding-top: 0;
      padding-bottom: 0;
      min-height: 48px;
      white-space: normal;
      display: flex;
      align-items: center;
      &:focus {
        border-radius: 12px;
        background: var(--gray400);
      }
    }
    .MuiChip-outlined {
      position: relative;
      margin-right: 5px;
      margin-top: 5px;
      margin-bottom: 5px;
      z-index: 3;
    }
  }
  fieldset {
    border: none;
  }
}
.popover-for-multiple-select {
  z-index: 4 !important;
  &.in-modal {
    z-index: 1301 !important;
  }
  .MuiMenu-paper {
    margin-top: 5px;
  }
  .MuiList-root {
    padding-top: 0;
    padding-bottom: 0;
  }
}
.MuiFormLabel-filled,
.MuiInputLabel-shrink {
  display: none !important;
}
.pointer-events-auto {
  pointer-events: auto !important;
}
.not_show {
  opacity: 0 !important;
  z-index: -99999 !important;
}
.ReactModalPortal {
  position: relative;
  z-index: 1300;
}
button, .button {
  box-shadow: none !important;
  span {
    font-weight: 700;
  }
}
.overflow-hidden {
  overflow: hidden;
}
.loaderForData.loader-all-screen {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 100000;
}
*[class*="Mui"] {
  font-family: "Inter", sans-serif !important;
}

.MuiButton-root:hover {
  background: var(--gray300);
}

.MuiPaper-elevation8 {
  @include dropdown;
}

.MuiAutocomplete-popper,
.MuiAutocomplete-listbox {
  border-radius: 10px !important;
  background: var(--white) !important;
}

.MuiAutocomplete-listbox,
.MuiAutocomplete-paper {
  @include dropdown;
  background: var(--white) !important;
  border-radius: 10px !important;
}

// .MuiAutocomplete-clearIndicator,
// .MuiAutocomplete-option {
//   background: none !important;
// }

.MuiList-root {
  .MuiMenuItem-root {
    @include text-body-s;
    padding: 0px 24px;
    height: 40px;
    white-space: normal;
  }
}

.MuiChip-label {
  max-width: 330px;
}

.MuiFormHelperText-contained {
  margin-left: 0;
}

button > div > span,
a > div > span {
  pointer-events: none;
}

.custom-button {
  border-radius: 10px;
  padding: 11px 16px;
  cursor: pointer;
  transition: 0.3s;
  font-size: 14px;
  font-weight: 700;
  max-width: 100% !important;
  border: 0;
  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }
  &_green {
    color: var(--primaryDarken);
    background: var(--gray400);
  }
  &_cta {
    color: var(--white);
    background: var(--CTA);
    &:hover {
      background: var(--CTAHover) !important;
    }
  }
}
.create-request-page {
  .custom-button {
    max-width: 280px !important;
    padding: 16px;
  }
}

.bold {
  font-weight: 700 !important;
}

input::placeholder {
  @include text-body-s;
  color: var(--gray800) !important;
  opacity: 1 !important;
  // font-size: 16px !important;
  //line-height: 22px !important;
}

.text-center {
  text-align: center !important;
}

.cursor-default {
  cursor: default !important;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}
//
//.or-divider {
//  position: relative;
//
//  &:before,
//  &:after {
//    content: "";
//    position: absolute;
//    top: 50%;
//    width: 60px;
//    height: 1px;
//    background: var(--gray600);
//    transform: translateY(-50%);
//  }
//
//  &:before {
//    right: 100%;
//    margin-right: 10px;
//  }
//
//  &:after {
//    left: 100%;
//    margin-left: 10px;
//  }
//}
