@mixin font-subtitle-s {
  /* subtitle/S */
  font-family: var(--TT);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 130% */
}
@mixin font-subtitle-m {
  /* subtitle/M */
  font-family: var(--TT);
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 145.455% */
}

@mixin font-subtitle-l {
  font-family: var(--TT);
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 150% */
}
