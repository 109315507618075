@import 'src/assets/styles/mixins'

.info-popup
  z-index: 2147483644
  &.MuiSnackbar-root
    white-space: pre-line
    bottom: 90px
    .MuiSnackbarContent-message
      line-height: 28px
      padding: 4px 0px

  .snackbar
    &_success
      &.MuiSnackbarContent-root
        background-color: var(--success)
      .link
        color: var(--gray600)
        &:hover
          color: var(--white)


    &_error
      &.MuiSnackbarContent-root
        background-color: var(--error)


    &_info
      &.MuiSnackbarContent-root
        background-color: var(--secondary)


    &_black
      &.MuiSnackbarContent-root
        background-color: var(--black)


    &_link
      &.MuiSnackbarContent-root
        background-color: var(--link)


  .link
    +font-body-xs
    transition: 0.2s
    // padding-top: 1px
    // font-weight: bold
    text-transform: lowercase
    margin-right: 8px
    text-decoration: none
    &:hover
      text-decoration: underline
    +screen_sm_max
      margin-right: 24px
