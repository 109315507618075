@mixin buttons-green
  background: var(--primaryLight)
  color: var(--white)
  text-transform: inherit
  &:disabled
    background-color: var(--gray500)
  &:hover
    background: var(--primaryDarken)
    color: var(--white)

@mixin buttons-cta
  background-color: var(--CTA)
  color: var(--white)
  text-transform: none
  &:hover
    background-color: var(--CTAHover)

@mixin buttons-gray
  background: var(--gray400)
  color: var(--primaryDarken)
  text-transform: inherit
  &:hover
    background: var(--primary)
    color: var(--white)

@mixin buttons-mobile
  +screen-sm-max
    width: 100%

@mixin buttons-s
  +font-button-s
  padding: 11px 16px
  border-radius: 10px

@mixin buttons-sm
  +font-button-sm
  padding: 11px 16px
  border-radius: 10px

// 15/20 + 14 * 2 = 48
@mixin buttons-m
  +font-button-m
  +border-m
  height: $height-m
  padding: 14px 16px

@mixin buttons-l
  +font-button-l
  height: $height-l
  padding: 18px 24px
  border-radius: 12px
  // +screen-sm-max
  //   width: 100%

@mixin buttons-mobile
  +screen-sm-max
    width: 100%

@mixin buttons-l-mobile-m
  +font-button-l
  +border-m
  padding: 18px 24px
  +screen-sm-max
    +font-button-m
    width: 100%
    padding: 14px 16px
    border-radius: 12px

@mixin buttons-upload-files
  +text-button-m
  +height-m
  +border-m
  display: inline-block
  line-height: $height-m
  min-width: 160px
  color: var(--white)
  background: var(--primary)
  text-align: center
  cursor: pointer
  flex-shrink: 0
  &:hover
    background: var(--primaryDarken)
    