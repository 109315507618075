
.buttons-green.MuiButton-root
  @include buttons-green

.buttons-cta.MuiButton-root
  @include buttons-cta

.buttons-gray.MuiButton-root
  @include buttons-gray

.buttons-s.MuiButton-root
  @include buttons-s

.buttons-m.MuiButton-root
  @include buttons-m

.buttons-l.MuiButton-root
  @include buttons-l

.buttons-l-mobile.MuiButton-root
  @include buttons-l-mobile-m
