@mixin font-body-heading-s {
  /* body heading / S */
  font-family: var(--TT);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 155.556% */
}

@mixin font-body-heading-m {
  /* body heading / M */
  font-family: var(--TT);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px; /* 130% */
}

@mixin font-body-heading-l {
  /* body heading / L */
  font-family: var(--TT);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 133.333% */
}

@mixin font-body-xs {
  /* body / XS */
  font-family: var(--Inter);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
}

@mixin font-body-xxs {
  /* body / XXS */
  font-family: var(--Inter);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
}

@mixin font-body-s {
  /* body / S */
  font-family: var(--Inter);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

@mixin font-body-m {
  /* body/M */
  font-family: var(--Inter);
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 152.941% */
}

@mixin font-body-l {
  /* body/L */
  font-family: var(--Inter);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 166.667% */
}

@mixin font-body-link-m {
  color: var(--brand-primary-light);

  /* body link / M */
  font-family: var(--Inter);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  text-decoration-line: underline;
}
