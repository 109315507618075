@import "assets/styles/mixins";

.FormDoubleRow {
  display: flex;
  max-width: 336px * 2 + 4 * $unit;
  gap: 4 * $unit;
  .FormGroup {
    margin-top: 0;
  }
}
.FormColumn {
  flex: 0 1 50%;
}
.FormGroup {
    margin-top: 3 * $unit;
    margin-bottom: 6 * $unit;
    max-width: 336px;
    &.fullWidth {
      max-width: unset;
    }
    @include screen_sm_max {
      max-width: 100%;
    }
    &.maxWidthX2 {
      max-width: 736px;
    }
    .shinkOff {
      label.MuiInputLabel-shrink {
        display: none;
        &.Mui-focused:not(.MuiFormLabel-filled) {
          display: block;
          transform: translate(16px, 16px);
          color: var(--gray800);
        }
      }
    }
    label {
      &.MuiInputLabel-formControl {
        transform: translate(16px, 16px);
      }
      &.MuiFormLabel-root {
        font-size: 15px;
      }
    }
    .MuiInputBase-root {
      border-radius: 10px;
      &:hover {
        box-shadow: 0px 0px 7px 2px lightgrey;
      }
    }
  
    &.readOnly {
      .FormGroup-HL {
        grid-template-areas: "g11" "g23";
        grid-template-columns: 1fr;
        max-width: 336px;
        .g13, .g21, .g22, .g31, .g33 {
          display: none;
        }
      }  
    }
    // .FormGroupRow
    &Row {
      margin: 3 * $unit 0;
    }
    // .FormGroup-HL { // Horizontal List
    &-HL { // Horizontal List
      display: grid;
      grid-template-columns: minmax(0, 1fr) 64px minmax(0, 1fr);
      grid-row-gap: 15px;
      align-items: center;
      padding-bottom: 0px;
      grid-template-areas: "g11 .   g13"
                           "g21 g22 g23"
                           "g31 .   g33";
      @include screen_sm_max {
        max-width: 100%;
        grid-template-columns: 1fr;
        grid-row-gap: 16px;
        grid-template-areas: "g11" "g21" "g31" "g22" "g13" "g23" "g33"
      }
      .g11 { grid-area: g11; }
      .g13 { grid-area: g13; }
      .g21 { grid-area: g21; }
      .g22 { grid-area: g22; }
      .g23 { grid-area: g23; }
      .g31 { grid-area: g31; }
      .g33 { grid-area: g33; }
  
      .FormGroup-HL-filter {
        background: var(--gray400);
        border-radius: 10px;
        label {
          @include text-body-s;
          color: var(--gray800);
          transform: translate(16px, 14px) scale(1);
          &.MuiInputLabel-shrink {
            display: none;
          }
          &.Mui-error {
            color: var(--muiError)
          }
        }
      }
  
      .FormGroup-HL_category__container {
        li { // item
          min-height: 5 * $unit;
          width: unset;
          margin: 0px 8px;
          border-radius: 10px;
          color: var(--black);
          background-color: var(--gray300);
          user-select: none;
          cursor: pointer;
          &.active {
            color: white;
            background-color: var(--primary);
            &:hover {
              background-color: var(--primaryDarken);
            }
          }  
          &:hover {
            background-color: var(--gray400);
          }
        }
      }
      .FormGroup-HL-second_title {
        @include text-body-s;
        padding-left: 8px;
      }
      .FormGroup-HL_items_container {
        padding: 9px 8px 4px 0px;
        border-radius: 10px;
        background-color: var(--gray300);
      }
      .FormGroup-HL_items {
        overflow: auto;
        padding: 0px;
        // width: 330px;
        height: 259px;
        max-height: 259px;
      }
      .g23 {
        .FormGroup-HL_items {
          @include screen_sm_max {
            height: unset;
            min-height: 48px;
          }
        }
      }
      .FormGroup-HL_arrows__container {
        width: 64px;
        display: flex;
        flex-direction: column;
        align-items: center;
        @include screen_sm_max {
          flex-direction: row;
          justify-content: center;
          width: unset;
        }
      }
      .FormGroup-HL_arrows__button {
        width: 32px;
        height: 32px;
        background-color: var(--gray300);
        margin: 8px 0px;
        @include screen_sm_max {
          width: 48px;
          height: 48px;
          margin: 0px 12px;
          transform: rotate(90deg);
        }
        &.active {
          color: white;
          background-color: var(--primary);
          &:hover {
            background-color: var(--primaryDarken);
          }
        }
        &:hover {
          background-color: var(--gray400);
        }
      }
      .FormGroup-HL_category {
        @include text-body-bold-s;
        display: flex;
        align-items: center;
        padding-left: 24px;
      }
      .FormGroup-HL_actions {
        @include link;
        padding-top: 1px;
        padding-left: 8px;
      }
      .MuiListItemText-primary {
        @include text-body-s;
        border-radius: 6px;
      }
      .MuiListItem-root {
        padding-left: 32px;
      }
    }
  
    .FormGroup-chips {
      margin: 16px -8px -8px 0px;
      .FormGroup-chips_item {
        @include text-body-sm;
        margin: 8px 8px 8px 0px;
        height: 40px;
        border-radius: 20px;
        color: var(--black);
        &:hover {
          background-color: var(--gray300);
        }
      }
      .FormGroup-chips_close-icon {
        margin-left: 10px;
        padding: 3px;
        height: 26px;
        width: 26px;
        border-radius: 50%;
        cursor: pointer;
        path {
          stroke: var(--gray700);
          fill: var(--gray700);
        }
        &:hover {
          background-color: var(--gray500);
        }
      }
      .FormGroup-chips_icon-button {
        margin: 8px 8px 8px 0px;
        height: 40px;
        border-radius: 20px;
        width: unset;
        .MuiChip-icon {
          margin: 0px 16px;
        }
        .MuiChip-label {
          display: none;
        }
        &:hover {
          background-color: var(--gray500);
        }
      }
      .MuiBadge-root {
        .MuiBadge-badge {
          top: 12px;
          right: 8px;
          background: var(--error);
          color: var(--white);
        }
      }
      .MuiChip-label {
        padding-left: 9px;
        padding-right: 21px;
      }
    }
  
    .FormGroup-row {
      margin-bottom: 0px;
      border-radius: 12px;
    }
    .FormGroup-label {
      @include text-body-s;
      margin-top: 3 * $unit;
      margin-bottom: 3px;
      color: var(--gray800);
      position: unset;
      transform: unset;
      &.Mui-focused {
        color: var(--black);
      }
    }
    .mt23 {
      margin-top: 23px;
    }
    .mb23 {
      margin-bottom: 23px;
    }
    .mt24 {
      margin-top: 24px;
    }
    .mb24 {
      margin-bottom: 24px;
    }
    .mb40 {
      margin-bottom: 40px;
    }
    .mb48 {
      margin-bottom: 48px;
    }
  
    // .FormGroup-title
    &-title {
      @include text-heading-h4;
      margin-bottom: 2 * $unit;
      .asterisk {
        color: var(--gray800);
      }
      &:empty {
        display: none;
      }
    }
    // .FormGroup-subTitle
    &-subTitle {
      @include text-body-s;
      color: var(--gray800);
    }
    // .FormGroup-input
    &-input {
      .FormGroup-input__adorment {
        opacity: 0;
        transition: opacity .5s;
      }
      &:hover {
        .FormGroup-input__adorment {
          opacity: 1;
        }
      }
      
    }
    .FormGroup-input input,
    .FormGroup-input_select,
    // .FormGroup-input_autocomplete,
    .FormGroup-wrap-input-tag,
    .FormGroup-input-tag {
      @include text-body-s;
      @include ellipsis;
      height: 48px;
      padding: 0px 16px;
      color: var(--black);
      background-color: var(--gray400);
      border-radius: 10px !important;
    }
    .FormGroup-wrap-input-tag {
      padding: 0;
    }
  
    .Mui-error {
      input::placeholder {
        color: var(--muiError) !important;
      }
    }
  
    .FormGroup-input_autocomplete {
      &.Mui-error {
        input::placeholder {
          color: var(--muiError) !important;
        }
      }
      // .FormGroup-input_autocomplete_searchIcon
      &_searchIcon {
        position: absolute;
        right: 36px;
        opacity: .7
      }
      &:hover {
        .FormGroup-input_autocomplete_searchIcon {
          opacity: 1;
        }
      }
      .MuiInputBase-root {
        @include text-body-s;
        // @include ellipsis;
        min-height: 48px;
        padding: 0px 8px;
        color: var(--black);
        background-color: var(--gray400);
        border-radius: 10px !important;
      }
      input {
        padding: 0px 48px 0px 0px;
      }
      .MuiAutocomplete-endAdornment {
        top: unset;
        right: 8px;
      }
    }
  
    .FormGroup-input_select {
      line-height: 48px !important;
    }
  }
  
  .FormAccordion {
    margin-bottom: 48px;
    box-shadow: unset !important;;
    &:before {
      background-color: transparent !important;;
    }
  }
  .FormAccordionHeader {
    @include text-body-s;
    height: 48px;
    padding: 0px 16px !important;
    background-color: var(--gray300) !important;
    color: var(--black);
    max-width: 336px;
    margin: 0px auto !important;
    border-radius: 10px !important;
    &:hover {
      background-color: var(--gray500) !important;
    }
    @include screen_sm_max {
      max-width: 100%;
    }
  }
  .FormAccordionBody {
    margin-top: 48px;
    flex-direction: column;
    align-items: center;
  }
  
  .FormEl {
    .FormGroup-submit {
      @include buttons-green;
      @include buttons-l;
      .cirular-progress {
        position: absolute;
      }
    }
  }
  