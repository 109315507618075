@import 'src/assets/styles/mixins'

.GlobalAlert
  // .GlobalAlert__body
  &__body
    min-width: 260px
    max-width: 375px
    min-height: 100px
    padding: 6 * $unit 4 * $unit 4 * $unit 4 * $unit
  // .GlobalAlert__text
  &__text
    +font-body-m
  // .GlobalAlert__buttons
  &__buttons
    text-align: center
    padding: 1 * $unit 4 * $unit 4 * $unit 4 * $unit
  // .GlobalAlert__button
  .GlobalAlert__button
    +buttons-green
    +buttons-l
    +buttons-mobile
    min-width: 130px

  .close
    position: absolute
    top: 5px
    right: 5px
    &:hover
      svg
        path
          color: var(--red)
