// Small tablets and large smartphones (landscape view)
$screen-xs-min: 320px

$screen-iphone12pro-min: 390px

// Small tablets and large smartphones (landscape view)
$screen-sm-min: 600px

// Small tablets (portrait view)
$screen-md-min: 977px

// Tablets and small desktops
$screen-lg-min: 1173px

// Large tablets and desktops
$screen-xl-min: 1589px

// Large desktops
// $screen-xl-min: 1901px;

$unit: 8px
@function Unit($n: 1)
    @return $n * $unit

$size-m: 6 * $unit
$size-l: 7 * $unit

$height-m: $size-m
$height-l: $size-l

$border-m: 12px
