.RequestLabel-root
  display: flex
  gap: 4px
  .type
    padding: 2px 8px
    font-family: var(--Inter)
    font-style: normal
    font-weight: 500
    font-size: 13px
    line-height: 17px
    color: var(--white)
    border-radius: 4px
    text-align: center
    &.type-offer
      background: var(--blue)
    &.type-request
      background: var(--primary)
    &.type-trade
      background: var(--yellow)    
      color: var(--blue)
    &.type-isMy
      background: var(--primaryLight)
