@import 'src/assets/styles/mixins'

// .dialog-confirm--button
//   cursor: pointer

.dialog-confirm
  h3
    margin: 2 * $unit 0
  p
    margin: 1 * $unit 0
  .MuiPaper-root
    max-width: 375px
  .close
    position: absolute
    top: 5px
    right: 5px
    &:hover
      svg
        path
          color: var(--red)
  &__btns
    display: grid
    grid-template-columns: repeat(2, 1fr)
    gap: 10px
    @include screen_rwd_max(360)
      grid-template-columns: 1fr
  .dialog-confirm__btns
    button
      max-width: 145px
      width: 100%
      font-size: 15px
      font-weight: 700
      text-transform: none
      @include screen_rwd_max(360)
        max-width: 300px
