@mixin accent
    font-weight: bold !important
    color: var(--CTA) !important
    padding-right: 2px



.NotDefault
    .NotDefault-hide
        display: unset !important
    .InfoNotDefault
        cursor: pointer
        opacity: 0.6
        fill: var(--CTA)
        &:hover
            opacity: 1
    .MuiInputBase-input
        @include accent

    // .NotDefault__accent
    .NotDefault__accent
        @include accent



.NotDefault-hide
    display: none !important
