@import "assets/styles/text-mixins"

.font-heading-hero
  @include font-heading-hero

.font-heading-hero-375
  @include font-heading-hero-375

.font-heading-h1
  @include font-heading-h1

.font-heading-h1-375
  @include font-heading-h1-375

.font-heading-h2
  @include font-heading-h2

.font-heading-h3
  @include font-heading-h3

.font-heading-h3-375
  @include font-heading-h3-375

.font-heading-h4
  @include font-heading-h4

.font-heading-h5
  @include font-heading-h5


.font-subtitle-m
  @include font-subtitle-m

.font-subtitle-l
  @include font-subtitle-l


.font-body-heading-s
  @include font-body-heading-s

.font-body-heading-m
  @include font-body-heading-m

.font-body-heading-l
  @include font-body-heading-l
  

.font-body-xs
  @include font-body-xs

.font-body-s
  @include font-body-s

.font-body-m
  @include font-body-m


.font-body-link-m
  @include font-body-link-m
