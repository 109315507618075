.preloaderBox {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  .preloader {
    position: absolute;
    top: calc(50% - 40px);
    left: calc(50% - 40px);
  }
}
