@import 'src/assets/styles/mixins'

.HandshakeIcon
    border-radius: 50%
    &.circle--my
        background: radial-gradient(closest-side, white 79%, transparent 80% 100%), conic-gradient(from 0, var(--gray600) var(--HandshakeIcon__icon_percent), var(--HandshakeIcon__icon_percent), var(--CTA))
    &.circle--opposite
        background: radial-gradient(closest-side, white 79%, transparent 80% 100%), conic-gradient(from 0, var(--gray600) var(--HandshakeIcon__icon_percent), var(--HandshakeIcon__icon_percent), var(--primary))
    &.circle--none, &.circle--full
        background: radial-gradient(closest-side, white 79%, transparent 80% 100%), conic-gradient(from 0, var(--gray400) var(--HandshakeIcon__icon_percent), var(--HandshakeIcon__icon_percent), var(--gray600))
    &.active
        path, line, rect
            stroke: var(--primary)
    // .HandshakeIcon__icon
    &__icon
        display: flex
        // padding: 1px
        width: Unit(4)
        height: Unit(4)
        // border: 2px solid transparent
        font-size: unset

        path, line, rect
            stroke: var(--gray600)

                // fill: var(--black)
    // &.circle--none
    // &__targetState
    //     // .HandshakeIcon__targetState--on
    //     &--on
    //         opacity: .7
    //         // background-color: var(--primary)
    //         border-color: var(--primary)
    //         // path
    //         //     fill: var(--primary)
    //     // .HandshakeIcon__targetState--off
    //     &--off
    //         opacity: .7
    //         border-color: var(--gray800)
    //         // background-color: var(--gray600)
    //         // path
    //         //     fill: var(--gray600)
    //     // .HandshakeIcon__targetState--deny
    //     &--deny
    //         opacity: .7
    //         border-color: var(--error)
    //         // background-color: var(--error)
    //         // path
    //         //     fill: var(--error)
