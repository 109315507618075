@import 'src/assets/styles/breakpoints'

.dialog-alert
  .MuiPaper-root
    max-width: 375px
  .close
    position: absolute
    top: 5px
    right: 5px
    &:hover
      svg
        path
          color: var(--red)
  button // check
    max-width: 145px
    width: 100%
    font-size: 15px
    font-weight: 700
    text-transform: none
    @include screen_rwd_max(360)
      max-width: 300px
